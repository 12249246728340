.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    margin-top: 20px;
    padding: 0 20px;
}

.listas {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0px;
}

.listaUsuarios {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lista {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 15px;
    width: 100%;
    height: calc(100vh - 200px);
    overflow: auto;
    padding: 5px;
    margin: 10px;
    scrollbar-width: thin;
    scrollbar-color: #E4FD3F #262626;
}

.lista::-webkit-scrollbar {
    width: 8px;
}

.lista::-webkit-scrollbar-track {
    background: #262626;
    border-radius: 10px;
}

.lista::-webkit-scrollbar-thumb {
    background: #E4FD3F;
    border-radius: 10px;
}

.user {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 420px;
    background-color: #1C1D1E;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid rgba(228, 253, 63, 0.1);
    transition: all 0.3s ease;
}

.user:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(228, 253, 63, 0.15);
}

.foto {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #E4FD3F;
    object-fit: cover;
}

.info {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.contName {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.name {
    font-family: 'Oswald', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
}

.contTags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin: 8px 0;
}

.tags {
    background-color: #262626;
    border-radius: 12px;
    padding: 3px 8px;
    min-width: auto;
    text-align: center;
    border: 1px solid rgba(228, 253, 63, 0.2);
}

.tag {
    font-family: 'Raleway', sans-serif;
    font-size: 11px;
    color: #E4FD3F;
    white-space: nowrap;
}

.contHabilidades {
    display: flex;
    gap: 10px;
    margin: 10px 0;
    padding: 8px;
    background-color: #262626;
    border-radius: 8px;
}

.posiciones, .habilidades {
    flex: 1;
    min-width: 0;
}

.txt {
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    color: #FFFFFF;
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contExtra {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.7);
    margin: 8px 0;
    overflow: hidden;
}

.extras {
    display: flex;
    margin: 2px 0;
    gap: 4px;
}

.extra {
    font-family: 'Raleway', sans-serif;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.7);
}

.contBtns {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: auto;
    padding-top: 10px;
}

.btnActivar, .btnDesactivar, .btnVer {
    padding: 6px 12px;
    border-radius: 15px;
    font-family: 'Oswald', sans-serif;
    font-size: 12px;
    font-weight: 500;
    transition: all 0.3s ease;
    min-width: 80px;
    text-align: center;
}

.btnActivar {
    background-color: #E4FD3F;
    color: #000000;
}

.btnActivar:hover {
    background-color: #c8e035;
    transform: scale(1.05);
}

.btnDesactivar {
    background-color: #262626;
    color: #FFFFFF;
    border: 1px solid rgba(228, 253, 63, 0.3);
}

.btnDesactivar:hover {
    background-color: #E4FD3F;
    color: #000000;
    transform: scale(1.05);
}

.btnVer {
    background-color: transparent;
    color: #E4FD3F;
    border: 1px solid #E4FD3F;
}

.btnVer:hover {
    background-color: #E4FD3F;
    color: #000000;
    transform: scale(1.05);
}

.title {
    font-family: 'Oswald', sans-serif;
    font-size: 32px;
    font-weight: 600;
    color: #E4FD3F;
    text-transform: uppercase;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.subTitle {
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.inline {
    color: rgb(0, 255, 0);
    font-size: 10px;
    margin-bottom: 2px;
    margin-left: 4px;
}

.offline {
    color: rgb(156, 156, 156);
    font-size: 10px;
    margin-bottom: 2px;
    margin-left: 4px;
}

/* Media queries para asegurar responsividad */
@media screen and (max-width: 1400px) {
    .lista {
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
}

@media screen and (max-width: 1200px) {
    .lista {
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }
}

@media screen and (max-width: 768px) {
    .lista {
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }
    
    .contBtns {
        flex-direction: column;
    }
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: #1C1D1E;
    border-radius: 10px;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    border: 1px solid rgba(228, 253, 63, 0.2);
    box-shadow: 0 5px 15px rgba(228, 253, 63, 0.1);
}

.modalTitle {
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    color: #E4FD3F;
    margin-bottom: 15px;
    text-align: center;
}

.modalText {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 10px;
    text-align: center;
}

.modalHighlight {
    color: #E4FD3F;
    font-weight: bold;
}

.modalWarning {
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    color: #ff6b6b;
    margin-bottom: 20px;
    text-align: center;
}

.modalButtons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
}

.modalCancelBtn, .modalConfirmBtn {
    padding: 8px 20px;
    border-radius: 20px;
    font-family: 'Oswald', sans-serif;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease;
    min-width: 100px;
}

.modalCancelBtn {
    background-color: transparent;
    color: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.modalCancelBtn:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: #FFFFFF;
    transform: scale(1.05);
}

.modalConfirmBtn {
    background-color: #E4FD3F;
    color: #000000;
    border: none;
}

.modalConfirmBtn:hover {
    background-color: #c8e035;
    transform: scale(1.05);
}