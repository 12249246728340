.conteiner {
    height: 1100px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    scrollbar-width: none;
    z-index: 2;
}

.miPubli {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #262626;
    border-radius: 12px;
    z-index: 2;
}

.crear {
    display: flex;
    justify-content: center;
    width: 98%;
    margin-bottom: 10px;
}

.msg {
    display: flex;
    justify-content: center;
    width: 98%;
    height: 40px;
    margin: 10px 5px 12px 5px;
}

.user {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: 2px solid #E4FD3F;
}

.contImg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 98%;
    height: 40px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.img {
    width: 220px;
    height: 170px;
    border-radius: 8px;
    margin-left: 60px;
    margin-bottom: 10px;
} 

.video{
    width: 220px;
    height: 170px;
    margin-bottom: 10px;
    margin-left: 60px;
}

.iconNot{
    width: 20px;
    height: 20px;
    border-radius: 100px;
    border: 2px solid #E4FD3F;
    margin-left: auto;
    margin-right: 35%;
    cursor: pointer;
}

.inp {
    width: 300px;
    height: 40px;
    border-radius: 7px;
    background-color: #0F0F0F;
    border: none;
    margin-left: 12px;
    margin-right: 12px;
    color: #FFFFFF;
}

.inp::placeholder {
    color: #FFFFFF;
}

.inp:hover {
    background-color: rgba(0, 0, 0, 0.327);
}

.btn {
    width: 60px;
    height: 40px;
    border-radius: 10px;
    font-size: 16px;
    background-color: rgb(56, 56, 56);
    font-family: Raleway;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
}

.btn:hover {
    background-color: rgba(0, 0, 0, 0.214);
    color: white;
    border-color: white;
    cursor: pointer;
}

.btnImg {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #0F0F0F;;
    width: 200px;
    height: 40px;
    border-radius: 6px;
    justify-content: center;
}

.inputext {
    /* margin-bottom: 8px; */
    display: none;
}

.btnText {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
}

.icon {
    width: 18px;
    height: 15px;
    margin-right: 10px;
}

.btnImg:hover {
    cursor: pointer;
    background-color: #1C1D1E;
}

.contBotonera {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 97%;
    height: 40px;
    background-color: #262626;
    margin-top: 20px;
    border-radius: 8px;
    z-index: 2;
    padding: 7px;

}

.contBotoneraOtro {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 416px;
    height: 40px;
    background-color: #262626;
    margin-top: 20px;
    border-radius: 8px;
    z-index: 2;
   padding: 7px;
}


.boton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 124px;
    height: 30px;
    border-radius: 6px;
}

.txtBoton {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
}

.boton:hover{
    cursor: pointer;
    background-color: #FFFFFF;
    .txtBoton{
        color: #1C1D1E;
    }
}

.botonOtro {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 30px;
    border-radius: 6px;
    margin-left: 5px;
    margin-right: 5px;
}

.botonOtro:hover{
    cursor: pointer;
    background-color: #FFFFFF;
    .txtBoton{
        color: #1C1D1E;
    }
}

.botonSelected{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 124px;
    height: 30px;
    border-radius: 6px;
    background-color: #FFFFFF;
    .txtBoton{
        color: #1C1D1E;
    }
}

.botonSelectedOtro{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 30px;
    border-radius: 6px;
    background-color: #FFFFFF;
    margin-left: 5px;
    margin-right: 5px;
    .txtBoton{
        color: #1C1D1E;
    }
}

.publicacionesDiv{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.errorMessage {
    background-color: rgba(255, 0, 0, 0.1);
    color: #ff4444;
    padding: 10px;
    margin: 10px 60px;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    font-family: Raleway;
}

@media screen and (max-width: 767px) {
    .conteiner {
        height: 95vh;
        width: 94vw;
        margin-top: 0px;
        margin-left: 0px;
        padding-bottom: 10vh;
    }
    
    .miPubli {
        width: 94vw;
    }
    
    .crear {
        width: 93vw;
        margin-bottom: 3vw;
    }
    
    .msg {
        width: 92vw;
        height: 40px;
        margin: 10px 5px 12px 5px;
    }
    
    .user {
        width: 10vw;
        height: 10vw;
    }
    
    .contImg {
        width: 90vw;
        height: 10vw;
        margin-bottom: 3vw;
        margin-left: 2vw;
    }
    
    .img {
        width: 70vw;
        height: 40vw;
        margin-left: 15vw;
        margin-bottom: 3vw;
    }
    
    .video{
        width: 70vw;
        height: 40vw;
        margin-bottom: 3vw;
        margin-left: 15vw;
    }
    
    .inp {
        width: 55vw;
        height: 10vw;
        margin-left: 3vw;
        margin-right: 3vw;
    }
    
    .btn {
        width: 15vw;
        height: 40px;
        border-radius: 10px;
        font-size: 16px;
        background-color: rgb(56, 56, 56);
        font-family: Raleway;
        font-size: 12px;
        font-weight: 600;
        line-height: 16.44px;
        text-align: left;
        color: #FFFFFF;
    }
    
    .btnImg {
        width: 43vw;
        height: 10vw;
    }
    
    .inputext {
        /* margin-bottom: 8px; */
        display: none;
    }
    
    .btnText {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.44px;
        text-align: left;
        color: #FFFFFF;
    }
    
    .icon {
        width: 18px;
        height: 15px;
        margin-right: 10px;
    }
    
    .btnImg:hover {
        cursor: pointer;
        background-color: #1C1D1E;
    }
    
    .contBotonera {
        width: 90vw;
        height: 40px;
        margin-top: 4vw;
        padding: 2vw;
    }

    .publicacionesDiv{
        width: 96vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .contBotoneraOtro {
        width: 90vw;
        height: 10vw;
        margin-top: 4vw;
        border-radius: 2vw;
        padding: 2vw;
    }
    
    
    .boton {
        width: 28vw;
        height: 30px;
    }
    
    .txtBoton {
        font-size: 4vw;
    }
    
    .botonSelected{
        width: 28vw;
        height: 30px;
    }
    
    .errorMessage {
        margin: 3vw 15vw;
        font-size: 3.5vw;
        padding: 2vw;
    }
}