.searchBar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 90vw;
    margin: 20px 0;
}

.filtersContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.searchInputs {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.searchGroup {
    display: flex;
    gap: 10px;
    flex: 2;
}

.inputText {
    flex: 1;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid rgba(228, 253, 63, 0.3);
    background-color: #262626;
    color: white;
    font-family: 'Raleway', sans-serif;
}

.selectFilter {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid rgba(228, 253, 63, 0.3);
    background-color: #262626;
    color: white;
    font-family: 'Raleway', sans-serif;
    cursor: pointer;
    min-width: 150px;
}

.selectFilter:hover {
    border-color: #E4FD3F;
}

.orderBtn {
    margin-left: auto;
    white-space: nowrap;
}

.buttonGroup {
    display: flex;
    gap: 10px;
}

.btn {
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid rgba(228, 253, 63, 0.3);
    background-color: #262626;
    color: white;
    font-family: 'Oswald', sans-serif;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn:hover {
    background-color: #E4FD3F;
    color: #000000;
}

.searchBtn {
    white-space: nowrap;
    min-width: 100px;
}

/* Agregar estilos para el slider de rango */
.rangeContainer {
    width: 100%;
    padding: 10px 0;
}

.rangeGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.rangeGroup label {
    color: white;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
}

.rangeInputs {
    display: flex;
    gap: 10px;
}

.rangeSlider {
    flex: 1;
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background: rgba(228, 253, 63, 0.3);
    outline: none;
}

.rangeSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #E4FD3F;
    cursor: pointer;
    transition: all 0.3s ease;
}

.rangeSlider::-webkit-slider-thumb:hover {
    transform: scale(1.2);
}

.rangeSlider::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #E4FD3F;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease;
}

.rangeSlider::-moz-range-thumb:hover {
    transform: scale(1.2);
}

.filtersSection {
    display: flex;
    gap: 15px;
    align-items: flex-start;
    flex-wrap: wrap;
}

.filterActions {
    display: flex;
    gap: 10px;
}

.clearBtn {
    background-color: #3a3a3a;
    color: #E4FD3F;
}

.clearBtn:hover {
    background-color: #E4FD3F;
    color: #000000;
}

.activeTags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    align-items: center;
}

.filterTag {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 4px 8px;
    background-color: rgba(228, 253, 63, 0.1);
    border: 1px solid rgba(228, 253, 63, 0.3);
    border-radius: 4px;
    color: white;
    font-size: 12px;
    font-family: 'Raleway', sans-serif;
}

.removeTag {
    background: none;
    border: none;
    color: #E4FD3F;
    font-size: 18px;
    cursor: pointer;
    padding: 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
}

.removeTag:hover {
    color: #ff4444;
}