.inicio {
    width: 100vw;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.contHero {
    width: 100vw;
    display: flex;
    flex-direction: column;
}

.hero {
    width: 100vw;
    height: 500px;
    display: flex;
    flex-direction: row;
    background-image: url("../../../assets/imgNosotrosHero.png");
    background-repeat: no-repeat;
    background-size: 100vw 500px;
}

.porque {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-top: 80px;
    padding-left: 160px;
    padding-bottom: 150px;
    background-image: url("../../../assets/gradienteC.png");
    background-repeat: no-repeat;
    background-size: 1200px 1200px;
}

.contContenido {
    display: flex;
    flex-direction: row;
    width: 100vw;
}

.contRow {
    display: flex;
    flex-direction: row;
}

.contTxtHero {
    width: 480px;
    display: flex;
    margin-left: 164px;
    margin-top: 166px;
}

.txtHero {
    font-family: Oswald;
    font-size: 54px;
    font-weight: 600;
    line-height: 75.6px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 7px;
}

.imgTwo {
    width: 528px;
    height: 384px;
    border-radius: 12px;
}

.imgTree {
    width: 536px;
    height: 821px;
    opacity: 80%;
    border-radius: 12px;
}

.imgAppFiveB {
    width: 150px;
    height: 303px;
    position: absolute;
    z-index: 1;
    top: 130px;
    left: 450px;
}

.contenido {
    width: 486px;
    height: 372px;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: flex-end;
    margin-top: 10px;
}

.balonG {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    
}

.sub {
    font-family: Oswald;
    font-size: 42px;
    font-weight: 600;
    line-height: 62.24px;
    text-align: right;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.subB {
    font-family: Oswald;
    font-size: 28px;
    font-weight: 600;
    line-height: 41.5px;
    text-align: left;

    color: #FFFFFF;
    margin-bottom: 15px;
}

.txt {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: right;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.txtB {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 15px;
    margin-top: 10px;
}

.txtL {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 5px;
}

.contListNum {
    display: flex;
    flex-direction: row;
    width: 100vw;
}

.contfour {
    width: 800px;
    display: flex;
    flex-direction: column;
}

.conttree {
    width: 344px;
    height: 464px;
    display: flex;
    flex-direction: column;
}

.conttwo {
    display: flex;
    flex-direction: row;
    width: 750px;
    justify-content: space-between;
}

.one {
    width: 350px;
    height: 314px;
    display: flex;
    flex-direction: column;
}

.two {
    width: 350px;
    height: 314px;
    display: flex;
    flex-direction: column;
}

.four {
    width: 718px;
    display: flex;
    flex-direction: column;
    margin-top: -150px;
}

.tree {
    width: 344px;
    height: 464px;
    display: flex;
    flex-direction: column;
}

.entrenadores {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 780px;
    padding-left: 160px;
    padding-bottom: 160px;
    background-image: url("../../../assets/gradienteD.png");
    background-repeat: no-repeat;
    background-size: 1500px 1000px;
    background-position: right 1px;
}

.contImages {
    position: relative;
    display: flex;
    width: 650px;
    height: 470px;
}

.contList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
}

.contImagesE {
    position: relative;
    display: flex;
    width: 650px;
    height: 500px;
    margin-left: 160px;
}

.imgAppFour {
    display: flex;
    flex-direction: column;
    width: 402px;
    height: 240px;
    position: absolute;
    z-index: 1;
    top: 520px;
    right: 160px;
}

.comillas {
    width: 80px;
    height: 80px;
    opacity: 40%;
}

.pensamiento {
    font-family: Raleway;
    font-size: 22px;
    font-weight: 500;
    line-height: 27.5px;
    text-align: left;
    color: #FFFFFF;
}

.btnRegis:hover{
    cursor: pointer;
    background-color: #000000;
    color: #FFFFFF;
}

.btnIniciar:hover{
    cursor: pointer;
    background-color: #E4FD3F;
    color: #000000;
}

.btnM:hover{
    cursor: pointer;
    color: #E4FD3F;
    border-bottom: 3px solid #E4FD3F;
}

.iconR:hover{
    cursor: pointer;
    filter: invert(10%) sepia(100%) saturate(10000%);
}

.logo:hover{
    cursor: pointer;
}

.contM {
    width: 480px;
    display: flex;
    flex-direction: column;
    margin-left: 160px;
    margin-right: 50px;
    z-index: 1;
}

.Mprincipal {
    font-family: Oswald;
    font-size: 54px;
    font-weight: 600;
    line-height: 75.6px;
    text-align: left;
    color: #FFFFFF;
}

.conSegundo {
    display: flex;
    width: 433px;
    margin-top: 20px;
}

.MSegundo {
    font-family: Raleway;
    font-size: 24px;
    font-weight: 400;
    line-height: 31.2px;
    text-align: left;
    color: #FFFFFF;
}

.contBtn {
    display: flex;
    flex-direction: row;
    width: 345px;
    height: 46px;
    justify-content: space-between;
    margin-top: 20px;
}

.btnR {
    width: 158px;
    background-color: #E4FD3F;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #000000;
}

.btnI {
    width: 158px;
    background-color: #FFFFFF;
    border-radius: 8px;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #000000;
}

.contImg {
    width: 800px;
    height: 500px;
}

.imgOne {
    width: 800px;
    height: 500px;
    opacity: 80%;
    mix-blend-mode: lighten;
}

.title {
    font-family: Oswald;
    font-size: 42px;
    font-weight: 600;
    line-height: 62.24px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 400px;
    margin-bottom: 80px;
}


.list {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
}

.listB {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 44px;
    margin-bottom: 12px;
    margin-top: 12px;
}

.balon {
    width: 24px;
    height: 24px;
    margin-right: 12PX;
}

.contEntre{
    display: flex;
    width: 450px;
    flex-direction: column;
}


@media only screen and (max-width: 767px) {

    .inicio {
        margin-top: 0px;
    }

    .contTxtHero{
        width: 100vw;
        margin-left: 0px;
        margin-top: 45vw;
    }
    .txtHero{
        text-align: center;
        margin-left: 0px;
        font-size: 14vw;
        line-height: 18vw;
    }
    .porque{
        padding-left: 0px;
        padding-top: 15vw;
        padding-bottom: 0px;
    }
    .contContenido{
        flex-direction: column-reverse;
    }
    .contenido{
        width: 100vw;
        align-items: center;
        height: auto;
        margin-bottom: 30px;
    }
    .balonG{
        display: none;
    }
    .sub{
        text-align: center;
        font-size: 11vw;
        line-height: 15vw;
        margin-bottom: 15vw;
    }
    .txt{
        text-align: center;
        margin-left: 2vw;
        margin-right: 2vw;
        font-size: 4vw;
        line-height: 6vw;
        margin-bottom: 15vw;
    }
    .contImages{
        width: 100vw;
        justify-content: center;
        align-items: center;
        height: auto;
    }
    .imgTwo{
        width: 90%;
        height: 90%;
        border-radius: 10px;
    }
    .imgAppFiveB {
        width: 30vw;
        height: 50vw;
        top: 25vw;
        left: 65vw;
    }
    .contListNum{
        margin-top: 15vw;
        flex-direction: column;
    }
    .conttwo {
        flex-direction: column;
        width: 100vw;
    }
    .one{
        width: 100vw;
        height: auto;
        margin-bottom: 15vw;
    }
    .txtB{
        text-align: center;
        font-size: 4vw;
        line-height: 7vw;
        margin-left: 2vw;
        margin-right: 2vw;
    }
    .two{
        width: 100vw;
        height: auto;
        margin-bottom: 15vw;
    }
    .conttree {
        width: 100vw;
        height: auto;
    }
    .tree {
        width: 100vw;
        height: auto;
    }
    .four{
        margin-top: 15vw;
        width: 100vw;
    }
    .contfour{
        width: 100vw;
    }
    .entrenadores{
        width: 100vw;
        flex-direction: column-reverse;
        padding-left: 0px;
        margin-top: 15vw;
        height: auto;
        padding-bottom: 25vw;
    }
    .contEntre{
        width: 100vw;
        margin-top: 15vw;
    }
    .contList{
        margin-bottom: 15vw;
    }
    .listB{
        height: auto;
        margin-top: 2vw;
        margin-bottom: 2vw;
        margin-left: 3vw;
        margin-right: 2vw;
    }
    .contImagesE{
        width: 100vw;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    }
    .imgTree{
        width: 90%;
        height: 100%;
        border-radius: 10px;
    }
    .imgAppFour {
        width: 85%;
        height: auto;
        top: 330px;
        right: 30px;
    }
    .comillas {
        width: 50px;
        height: 50px;
        opacity: 40%;
    }
    .pensamiento {
        font-size: 4vw;
        line-height: 4vw;
        text-align: center;
    }
    .subB{
        text-align: center;
        font-size: 6vw;
        line-height: 8vw;
        margin-bottom: 5vw;
    }
    .balon{
        margin-left: 2vw;
        width: 5vw;
        height: 5vw;
    }
    .txtL{
        font-size: 4vw;
        line-height: 6vw;
        margin-left: 3vw;
    }
}
