/* Variables CSS para consistencia */
:root {
    --primary-color: rgba(228, 253, 63, 1);
    --text-color: #ffffff;
    --background-dark: #0f0f0f;
    --font-oswald: "Oswald", sans-serif;
    --font-raleway: "Raleway", sans-serif;
    --spacing-unit: 8px;
  }
  
  /* Optimización del modal principal */
  .modalEntrenamiento {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    z-index: 999;
  }
  
  .modalContent {
    display: flex;
    position: relative;
    width: 85%;
    max-width: 1000px;
    height: 85vh;
    background-color: #0f0f0f;
    padding: 0;
    border-radius: 12px;
    overflow: hidden;
    margin-top: 5vh;
  }

  @media (max-width: 768px) {
    .modalEntrenamiento {
      align-items: flex-start;
      padding-top: 78px;
      height: 100%;
      overflow-y: auto;
    }
  
    .modalContent {
      width: 100%;
      height: auto;
      min-height: calc(100vh - 60px);
      margin: 0;
      border-radius: 0;
      flex-direction: column;
      position: relative;
    }
  }