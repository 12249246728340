.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 20px;
    background-color: #000000;
    border-radius: 10px;
}

.totales {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    min-height: 40vh;
    border-radius: 15px;
    background-color: #1C1D1E;
    margin-bottom: 2vh;
    padding: 20px;
    box-shadow: 0 2px 12px rgba(255, 255, 255, 0.164);
}

.dataTotales {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.graficasTotales {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    min-height: 400px;
    gap: 40px;
    padding: 20px;
}

.graficaContainer {
    flex: 1;
    min-height: 350px;
    max-height: 350px;
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.Uregistrados {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 25px;
    background-color: rgb(0, 0, 0);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.05);
}

.title {
    margin: 0;
    color: #ffffff;
    font-family: Oswald;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 20px;
}

.subTitle {
    margin: 0;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    margin-left: 2px;
    margin-right: 4px;
}

.num {
    margin: 0;
    color: #E4FD3F;
    font-size: 18px;
    font-weight: 600;
    margin-left: 4px;
    margin-right: 2px;
}

@media (max-width: 1024px) {
    .graficasTotales {
        flex-direction: column;
    }

    .graficaContainer {
        width: 100%;
    }
}

.mapContent {
  width: 100%;
  height: 700px;
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.mapaContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
}

.svgContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.mapaTitle {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  color: #FFFFFF;
  margin-bottom: 15px;
}

.tooltip {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  padding: 10px 15px;
  border-radius: 8px;
  color: #FFFFFF;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  pointer-events: none;
  transform: translate(-50%, -120%);
  white-space: pre-line;
  border: 1px solid #E4FD3F33;
  z-index: 1000;
  min-width: 150px;
  text-align: left;
}

@media (max-width: 768px) {
  .mapContent {
    height: 500px;
    padding: 15px;
  }

  .tooltip {
    font-size: 12px;
    padding: 8px 12px;
    min-width: 120px;
  }
}

@media (max-width: 480px) {
  .mapContent {
    height: 400px;
    padding: 10px;
  }
}