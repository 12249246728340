.noticias {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1200px;
    height: 600px;
    margin-top: 20px;
}

.container {
    width: 90vw;
    margin: 0 auto;
    padding: 20px;
}

.title {
    font-family: 'Oswald', sans-serif;
    font-size: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 30px;
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-top: 20px;
}

.formSection, .previewSection {
    background: #1a1a1a;
    border-radius: 12px;
    padding: 20px;
    border: 1px solid rgba(228, 253, 63, 0.1);
}

.sectionHeader {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(228, 253, 63, 0.1);
}

.subtitle {
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    color: #FFFFFF;
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.logoUpload {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.logoPreview {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: contain;
    border: 2px solid rgba(228, 253, 63, 0.3);
    transition: all 0.3s ease;
    background-color: #262626;
    padding: 10px;
}

.logoPreview:hover {
    border-color: #E4FD3F;
}

.uploadText {
    font-size: 14px;
    color: #E4FD3F;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.label {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color: #FFFFFF;
}

.input, .textarea {
    padding: 10px;
    border-radius: 8px;
    background: #262626;
    border: 1px solid rgba(228, 253, 63, 0.3);
    color: #FFFFFF;
    font-family: 'Raleway', sans-serif;
}

.textarea {
    min-height: 100px;
    resize: vertical;
}

.mediaSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mediaButtons {
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.mediaButtons .btn {
    min-width: 120px;
    text-align: center;
}

.btn {
    padding: 10px 20px;
    border-radius: 8px;
    background: #262626;
    border: 1px solid rgba(228, 253, 63, 0.3);
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: 'Raleway', sans-serif;
}

.btn:hover, .btn.active {
    background: #E4FD3F;
    color: #000000;
}

.submitBtn {
    padding: 12px;
    border-radius: 8px;
    background: #E4FD3F;
    border: none;
    color: #000000;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: 'Raleway', sans-serif;
}

.submitBtn:hover {
    opacity: 0.9;
}

/* Estilos para la vista previa */
.previewCard {
    background: #262626;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.cardHeader {
    display: flex;
    align-items: center;
    padding: 15px;
    gap: 15px;
}

.previewLogo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: contain;
    background-color: #262626;
    padding: 5px;
}

.headerInfo {
    flex: 1;
}

.previewTitle {
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    color: #FFFFFF;
    margin: 0;
}

.previewSubtitle {
    font-size: 14px;
    color: #A4A4A4;
}

.timestamp {
    font-size: 12px;
    color: #A4A4A4;
}

.cardContent {
    width: 100%;
    background-color: #262626;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.previewText {
    width: 90%;
    color: #FFFFFF;
    margin: 0 auto 15px auto;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    line-height: 1.4;
    text-align: left;
}

.previewMedia {
    width: 90%;
    height: 400px;
    object-fit: contain;
    border-radius: 8px;
    margin: 15px auto;
    background-color: #1a1a1a;
    display: block;
}

video.previewMedia {
    width: 90%;
    height: 400px;
    object-fit: contain;
    background-color: #1a1a1a;
}

.cardFooter {
    display: flex;
    padding: 15px;
    border-top: 1px solid rgba(228, 253, 63, 0.1);
    justify-content: space-between;
    align-items: center;
}

.reaction {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #FFFFFF;
}

.reactionIcon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.reaction:last-child .reactionIcon {
    width: 32px;
    height: 32px;
}

/* Modal styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: #262626;
    padding: 30px;
    border-radius: 12px;
    text-align: center;
    color: #FFFFFF;
}

.subTitle {
    font-family: Oswald;
    font-size: 28px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
}

.contFoto {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 600px;
    margin-top: 30px;
    cursor: pointer;
}

.cont {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100px;
    margin-right: 20px;
}

.seccionName {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 12px;
}

.contNoticias {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 600px;
    height: 500px;
    border: 2px solid rgb(75, 75, 75);
}

.foto {
    width: 200px;
    height: 80px;
    margin-right: 15px;
    border-radius: 5px;
    margin-left: 15px;
}

.inp {
    display: flex;
    width: 250px;
    height: 40px;
    border-radius: 8px;
    margin-left: 12px;
    background-color: #262626;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: left;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
}

.contVista {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 600px;
    height: 500px;
    border: 2px solid rgb(75, 75, 75);
}

.contData {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 600px;
    height: 100px;
    margin-top: 10px;
}

.botonera{
    display: flex;
    flex-direction: row;
}

.btnA {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #000000;
    background-color: #E4FD3F;
    border-radius: 8px;
    width: 300px;
    height: 45px;
    margin-top: 30px;
    cursor: pointer;
}

.preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 400px;
    margin-top: 10px;
    background-color: #262626;
    border-radius: 12px;
}

.contTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: 400px;
    margin-top: 10px;
}

.imgNoticia, .video {
    display: none;
}

.logo {
    width: 70px;
    height: 70px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 50%;
}

.data {
    display: flex;
    flex-direction: column;
    width: 150px;
}

.name {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.44px;
    text-align: left;
    color: #FFFFFF;
}

.link {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: left;
    color: #FFFFFF;
}

.time {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    text-align: right;
    color: #A4A4A4;
    margin-left: 60px;
}

.contenido {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    width: 380px;
    /* height: 300px; */
    margin-top: 10px;
}

.contTxt {
    display: flex;
    width: 370px;
    margin-left: 10px;
    margin-bottom: 15px
}

.txt {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 500;
    line-height: 18.78px;
    text-align: left;
    color: #FFFFFF;
}

.reacciones {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: 380px;
    height: 50px;
}

.reaccion {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.reaccionB {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 120px;
}

.value {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.71px;
    text-align: left;
    color: #FFFFFF;
}

.icon {
    margin-right: 10px;
    margin-left: 10px;
    width: 25px;
    height: 25px;
}

.iconB {
    margin-right: 5px;
    margin-left: 10px;
    width: 50px;
    height: 40px;
    margin-top: 5px;
}

.videoUploadContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.progressBar {
    width: 100%;
    height: 4px;
    background-color: rgba(228, 253, 63, 0.1);
    border-radius: 2px;
    overflow: hidden;
    margin-top: 5px;
}

.progressFill {
    height: 100%;
    background-color: #E4FD3F;
    transition: width 0.3s ease;
}

.errorMessage {
    background-color: rgba(255, 0, 0, 0.1);
    color: #ff4444;
    padding: 10px;
    margin: 10px 0;
    border-radius: 8px;
    font-size: 14px;
    text-align: center;
    font-family: Raleway;
    width: 100%;
}

/* Ajustes responsive */
@media screen and (max-width: 767px) {
    .errorMessage {
        font-size: 3.5vw;
        padding: 2vw;
        margin: 2vw 0;
    }

    .progressBar {
        height: 3px;
    }
}
  