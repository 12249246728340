.conteiner {
    display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 999;
}

.modalContent {
    display: flex;
    flex-direction: column;
  position: relative;
  width: 85%;
  max-width: 1000px;
  height: 80vh;
  background-color: #0f0f0f;
  padding: 0;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 5vh;
}

.contTitle {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
}

.seccion {
    font-family: Oswald;
    font-size: 32px;
    font-weight: 400;
    line-height: 38.4px;
    text-align: left;
    color: #FFFFFF;
    margin-left: 20px;
}

.metricas {
    display: flex;
    flex-direction: row;
    width: 900px;
    height: 400px;
}

.posicions{
    display: flex;
    flex-direction: row;
    width: 90%;
    height: 400px;
    align-self: center;
}

.contRadar {
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 400px;
    align-items: center;
}

.contRadarDes {
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 400px;
    align-items: center;
}

.etiqueta {
    display: flex;
    flex-direction: row;
    width: 450px;
    height: 50px;
    align-items: center;
    justify-content: center;
}

.radar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/GraficoBase.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-size: 225px 260px;
    width: 350px;
    height: 300px;
    padding-left: 30px;
    padding-bottom: 0px;
}

.icon {
    width: 20px;
    height: 20px;
}

.etiquetaTxt {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 29.64px;
    text-align: center;
    color: #FFFFFF;
    margin-left: 10px;
    margin-right: 10px;
}

.btnCloset {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-size: 30px;
    font-weight: 600;
    color: #FFFFFF;
}

.btnCloset:hover {
    cursor: pointer;
    color: #E4FD3F;
}

.campo {
    width: 500px;
    height: 150px;
    margin-bottom: 15px;
}

.sub {
    font-family: Oswald;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.78px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.contPosicion {
    display: flex;
    width: 160px;
    height: 30px;
    border-radius: 100px;
    background-color: #262626;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-left: 5px;
}

.posiciones {
    display: flex;
    flex-direction: row;
}

.posicion {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.61px;
    text-align: center;
    color: #FFFFFF;
}

.botonera {
    display: none;
}

.btnAgregar {
    display: none;
}

.userCard {
    display: none;
}

.contCampo{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    background-image: url("../../assets/CampoLimpio.png");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }

  .Portero{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 20%;
    left: 46%;
  }

  .DefensaCentral{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 35%;
    left: 46%;
  }

  .MediocentroOfensivo{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 73%;
    left: 46%;
  }

  .MediocentroDefensivo{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 50%;
    left: 46%;
  }

  .Mediocentro{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 62%;
    left: 46%;
  }

  .DelanteroCentro{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 85%;
    left: 46%;
  }

  .DefensaLateralIzquierdo{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 35%;
    left: 25%;
  }

  .DefensaLateralDerecho{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 35%;
    right: 25%;
  }

  .InteriorIzquierdo{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 62%;
    left: 20%;
  }

  .InteriorDerecho{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 62%;
    right: 20%;
  }

  .ExtremoIzquierdo{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 80%;
    left: 30%;
  }

  .ExtremoDerecho{
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 80%;
    right: 30%;
  }

@media screen and (max-width: 767px) {
    .conteiner {
        align-items: flex-start;
        padding-top: 75px;
        height: 100%;
        overflow-y: auto;
    }

    .modalContent {
        width: 100%;
        height: auto;
        min-height: calc(100vh - 60px);
        margin: 0;
        border-radius: 0;
        flex-direction: column;
        position: relative;
        background-color: #0f0f0f;
        background-image: none;
    }

    .contTitle {
        margin-top: 2vw;
        margin-bottom: 2vw;
        display: flex;
        width: 96vw;
        justify-content: left;
        align-items: flex-start;
        margin-left: 2vw;
    }

    .seccion {
        font-size: 7vw;
        text-align: left;
    }

    .metricas {
        display: flex;
        flex-direction: column;
        width: 98vw;
        height: 70vh;
        align-items: center;
    }

    .contRadar {
        width: 88vw;
        height: auto;
        margin-left: 1vw;
    }

    .contRadarDes {
        display: none;
    }

    .etiqueta {
        display: none;
    }

    .radar {
        background-size: 225px 260px;
        width: 350px;
        height: 300px;
        padding-left: 30px;
        padding-bottom: 0px;
    }

    .btnCloset {
        display: none;
    }

    .btnCloset:hover {
        cursor: pointer;
        color: #E4FD3F;
    }

    .campo {
        width: 110vw;
        height: 20vh;
        margin-bottom: 5vw;
        margin-top: 5vw;
    }

    .sub {
        font-size: 4vw;
        margin-bottom: 2vw;
    }

    .contPosicion {
        width: 40vw;
        height: 8vw;
        margin-bottom: 3vw;
        margin-left: 2vw;
        margin-left: 2vw;
    }

    .posicion {
        font-size: 4vw;
    }

    .botonera {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 96vw;
        margin-left: 2vw;
        height: 8vh;
        background-color: #262626;
        border-radius: 10px;
        margin-bottom: 3vw;
    }

    .btnMenu {
        width: 45vw;
        height: 6vh;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2vw;
        margin-left: 2vw;

    }

    .btnMenuSelect {
        width: 45vw;
        height: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        margin-right: 2vw;
        margin-left: 2vw;
        border-radius: 10px;

        .txtMenu {
            color: #1C1D1E
        }
    }

    .txtMenu {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.44px;
        text-align: left;
        color: #FFFFFF;
    }

    .btnAgregar {
        display: block;
        width: 94vw;
        height: 6vh;
        background-color: #E4FD3F;
        color: #000000;
        border-radius: 10px;
        font-size: 4vw;
        font-weight: 600;
        position: fixed;
        bottom: 13vh;
        left: 3vw;
    }

    .userCard {
        display: flex;
        flex-direction: row;
        width: 96vw;
        margin-left: 0px;
        height: 8vh;
        background-color: #262626;
        border-radius: 10px;
        align-items: center;
        margin-bottom: 3vw;
    }

    .foto {
        width: 10vw;
        height: 10vw;
        border-radius: 50%;
        border: 2px solid #E4FD3F;
        margin-left: 2vw;
    }

    .info {
        display: flex;
        flex-direction: column;
        margin-left: 3vw;
        width: 50vw;
    }

    .name {
        font-family: Oswald;
        font-size: 4vw;
        font-weight: 500;
        line-height: 29.64px;
        text-align: left;
        color: #FFFFFF;
        margin-left: 0px;
        margin-right: 0px;
    }

    .position {
        font-family: Oswald;
        font-size: 3vw;
        font-weight: 500;
        line-height: 29.64px;
        text-align: left;
        color: #FFFFFF;
        margin-left: 0px;
        margin-right: 0px;
    }

    .contCampo{
        margin-bottom: 1vh;
      }
}