.conteiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    border-radius: 10px;
    margin-right: 20PX;
    width: 100%;
}

.modalContent{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.conSubGrafico {
    width: 25%;
    height: 520px;
    padding: 10px;
    background-color: #1a1a1a;
    border-radius: 8px;
    margin-left: 20px;
}

.grafica{
    width: 100%;
    height: 450px;
}

.cont{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.contHistorico {
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 480px;
    justify-content: left;
    align-items: flex-start;
    margin-left: 10px;
    margin-top: -10px;
}

.titleMAnd {
    display: none;
}

.contTitle {
    display: flex;
    flex-direction: row;
}

.title {
    font-family: Oswald;
    font-size: 32px;
    font-weight: 400;
    line-height: 38.4px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 5px
}

.txtCerrar{
    font-family: Raleway;
    font-size: 28px;
    font-weight: 700;
    line-height: 22.31px;
    text-align: right;
    color: #ffffff;
    margin-left: 10px;
    cursor: pointer;
    margin-left: auto;
}

.contSubHistorico {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.txtFecha{
    font-family: Oswald;
    font-size: 20px;
    font-weight: 500;
    line-height: 26.66px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 2px;
}

.contAnalisis{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.txtAnalisis{
    font-family: Oswald;
    font-size: 24px;
    font-weight: 500;
    line-height: 31.3px;
    text-align: left;
    color: #FFFFFF;
    margin-top: 5px;
    margin-right: 20px;
}

.contResumen{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: left;
    margin-top: 10px;
}

.txtResumen{
    font-family: Raleway;
    font-size: 15px;
    font-weight: 400;
    line-height: 20.61px;
    text-align: left;
    color: white;
}

.contEntrenamiento{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: left;
    margin-top: 10px;
}

.contMetricas{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: left;
    margin-top: 10px;
}

.contComentario{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.txtComentario{
    width: 90%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #ffffff;
    background-color: #262626;
    color: #ffffff;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.btnGuardar{
    width: 96%;
    height: 40px;
    background-color: #E4FD3F;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 600;
    line-height: 17.61px;
    text-align: center;
    color: #1C1D1E;
    border-radius: 8px;
    cursor: pointer;
}

.txtVideo{
    font-family: Raleway;
    font-size: 15px;
    font-weight: 400;
    line-height: 20.61px;
    text-align: left;
    color: #5451fb;
    cursor: pointer;
}

.txtVideo:hover{
    color: #0000fc;
    text-decoration: underline;
}

.contVideo{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-left: 10px;
    width: 300px;
    height: 300px;
}

.comentario{
    font-family: Raleway;
    font-size: 15px;
    font-weight: 400;
    line-height: 20.61px;
    text-align: left;
    color: white;
}

.contComentarioVideo{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-top: 15px;
}

@media screen and (max-width: 767px) {
    .conteiner {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        z-index: 1001;
        position: fixed;
        margin-top: 0px;
    }

    .cont{
        width: 100vw;
        flex-direction: column;
        margin-top: 30px;
        height: 75vh;
    }

    .grafica{
        height: 55vh;
    }
    
    .modalContent {
        width: 100vw;
        height: 100vh;
        padding: 0px;
        border-radius: 0px;
        overflow-y: scroll;
        scrollbar-width: none;
        flex-direction: column;
    }

    .conSubGrafico {
        margin-left: 2vw;
        margin-top: 10vw;
        width: 96vw;
        padding: 0px;
    }

    .contHistorico {
        width: 96vw;
        margin-left: 2vw;
        height: 100vh;
    }

    .titleMAnd {
        display: flex;
        position: fixed;
        top: 19vw;
        right: 5vw;
        z-index: 1003;
        font-size: 5vw;
        font-weight: 600;
        text-align: center;
        color: #E4FD3F;
    }

    .contTitle {
        margin-top: 3vw;
        margin-left: 0px;
        width: 94vw;
    }

    .title {
        font-size: 8vw;
        line-height:8vw;
        margin-top: 1vw;
    }

    .txtCerrar{
        display: none;
    }

    .contSubHistorico {
        display: flex;
        flex-direction: column;
        width: 98vw;
        align-items: center;
    }

    .contVideo{
        width: 96vw;
        height: 67vh;
        padding-bottom: 14vh;
        padding-top: 3vh;
        margin-top: 0px;
        margin-left: 0px;
    }
}