.conteiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    border-radius: 10px;
    margin-right: 20PX;
    width: 100%;
}

.contenido{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.metricas{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
    height: 100%;
}

.scoreChart{
    width: 70%;
    height: 90%;
    margin-top: 40px;
}

.contData{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 35px;
}

.rangoFechas {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 15px;
    gap: 20px;
    margin-left: 20px;
    margin-right: 50px;
    width: 90%;
}

.contBSD{
    background-image: url("../../../assets/BSDfondo.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 100%;
    height: 60px;
    margin-top: 20px;
    width: 70px;
}

.contOtrasMetricas{
    margin-top: 30px;
}

.bsdText{
    color: #1c1d1e;
    font-family: Oswald;
    font-weight: 400;
    text-align: center;
}

.bsdValor{
    font-size: 28px;
    line-height: 41.5px;
    color: #1c1d1e;
    font-family: Oswald;
    font-weight: 400;
    text-align: center;
}

.avatar{
    width: 40px;
    height: 50px;
    margin-top: 20px;
    margin-left: 30px;
}

.contMetricas{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-top: 15px;
}

.subTitle{
    font-family: Oswald;
    font-size: 20px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: left;
    color: #FFFFFF;
}

.contValores{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 20px;
}

.txt{
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #FFFFFF;
}

.valor{
    font-family: Oswald;
    font-size: 20px;
    font-weight: 400;
    line-height: 29.64px;
    text-align: left;
    color: #FFFFFF;
}

.icono{
    width: 30px;
    height: 30px;
}

.btnCerrar{
    font-family: Oswald;
    font-size: 30px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: right;
    color: #FFFFFF;
    align-self: end;
    position: absolute;
    right: 15px;
    top: 20px;
    cursor: pointer;
}

.title {
    font-family: Oswald;
    font-size: 32px;
    font-weight: 400;
    line-height: 17.78px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.tabText{
    color: #FFFFFF;
    font-family: Oswald;
    font-size: 14px;
    font-weight: 400;
    line-height: 20.75px;
    text-align: left;
}

.fechaInput {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.fechaInput label {
    color: white;
    font-size: 14px;
}

.fechaInput input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid rgb(103, 105, 92);
    background: white;
    color: black;
}

.fechaInput input:focus {
    outline: none;
    border-color: rgba(228, 253, 63, 1);
} 
  

  

@media screen and (max-width: 767px) {
    .conteiner{
        width: 100vw;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 10vh;
        margin-top: 35px;
    }

    .metricas{
        width: 98vw;
        margin-top: 2vw;
    }

    .contOtrasMetricas{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        width: 98vw;
        height: 70px;
        position: relative;
        padding-left: 10px;
    }

    .contMetricas{
        margin-top: 50px;
    }

    .contValores{
        height: 70px;
        margin-left: 10px;
    }

    .subTitle{
        position: absolute;
    }

    .txt{
        font-size: 3vw;
        line-height: 15px;
    }

    .title{
        font-size: 4vw;
    }

    .tabText{
        font-size: 12px;
        line-height: 15.75px;
    } 
    
    .scoreChart{
        width: 98vw;
        height: 40vh;
        margin-top: 40px;
        margin-left: 10px;
    }
    
    .contenido{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}