.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.subContainer{
    padding-top: 12vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.feed{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    min-width: 35%;
    max-width: 35%;
}